import { createLocalStorageStore } from './utils/createLocalStorageStore';

const IDEALIST_MOVEMENT_KEY = 'idealistPreSignupJoinMovement';

const { useValue, setValue } = createLocalStorageStore<string>({
  key: IDEALIST_MOVEMENT_KEY,
});

// Actions

export function setPreSignupJoinedMovement(movementSlug: string | null) {
  setValue(movementSlug);
}

// Hooks

export function usePreSignupJoinedMovement() {
  const value = useValue();
  return { preSignupMovement: value };
}
