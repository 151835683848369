import invariant from 'invariant';
import { useMemo } from 'react';

import { Box } from 'components/Box/Box';
import { Button } from 'components/Button/Button';
import { HeadingSmall } from 'components/Text/HeadingSmall';
import { RichText } from 'components/Text/RichText';
import { RichTextHeadline } from 'components/Text/RichTextHeadline';
import { CmsApiLink } from 'modules/cms/api/types/CmsApiLink';
import { CmsApiSplitBlockAppearance } from 'modules/cms/api/types/blocks/CmsApiSplitContentBlock';
import { CmsButtonLoadable } from 'modules/cms/components/Button/CmsButtonLoadable';
import { colors, gutterWidth } from 'theme/theme';
import { showModal } from 'zustand-stores/modalStore';
import { setPreSignupJoinedMovement } from 'zustand-stores/preSignupJoinMovementStore';

import {
  CmsSplitContentBlockMovementCounterDigit,
  CmsSplitContentBlockMovementCounterDigitContainer,
  CmsSplitContentBlockMovementCounterDigitSlot,
  CmsSplitContentBlockMovementPeopleJoined,
} from './CmsSplitContentBlock.styled';

type Props = {
  label: string | null;
  headline: string | null;
  html: string;
  button: CmsApiLink | null; // Used by TEXT
  hideButton: boolean; // Used by TEXT
  appearance: CmsApiSplitBlockAppearance;
  index: number;
  blockId: string;
  imageTextBlock: boolean;
  showBackgroundImage: boolean;
  blockIndex: number;
  pageUpdated: string;
  showTextBox: boolean;
  movementSignupButtonText?: string;
  movementUserLoggedIn?: boolean;
  movementUserSignedUp?: boolean;
  movementSignUp?: () => void;
  movementCount?: number;
  movementSlug?: string;
};

const digitsSlot = (number: number) => (
  <CmsSplitContentBlockMovementCounterDigitSlot $number={number}>
    {Array.from(Array(10).keys()).map((key) => (
      <CmsSplitContentBlockMovementCounterDigit>
        {key}
      </CmsSplitContentBlockMovementCounterDigit>
    ))}
  </CmsSplitContentBlockMovementCounterDigitSlot>
);

export function CmsSplitContentTextContentInner({
  appearance,
  blockId,
  blockIndex,
  imageTextBlock,
  index,
  pageUpdated,
  label,
  headline,
  html,
  button,
  hideButton,
  showBackgroundImage,
  showTextBox,
  movementSignupButtonText,
  movementUserLoggedIn,
  movementUserSignedUp,
  movementSignUp,
  movementCount,
  movementSlug,
}: Props) {
  const boxShadow = useMemo(() => {
    if (showTextBox) {
      return ['0 0 6px rgba(0,0,0,0.3)'];
    }

    if (showBackgroundImage) {
      return ['0 4px 9px rgba(0, 0, 0, 0.1)', null, 'none'];
    }

    return undefined;
  }, [showBackgroundImage, showTextBox]);

  const joinMovement = () => {
    if (movementUserLoggedIn) {
      invariant(movementSignUp, 'Missing movementSignUp function');
      movementSignUp();
    } else {
      invariant(movementSlug, 'Movement slug better not be null');
      showModal('SIGNUP', {
        title: 'Join our movement',
        onSignup: () => {
          setPreSignupJoinedMovement(movementSlug);
        },
        onLogin: () => {
          setPreSignupJoinedMovement(movementSlug);
        },
      });
    }
  };

  let digits;
  if (movementCount !== undefined) {
    digits = movementCount.toString().split('');
    while (digits.length < 4) {
      digits.unshift('0');
    }
  }

  return (
    <Box
      height="100%"
      width="100%"
      bg={colors.white}
      boxShadow={boxShadow}
      borderRadius={showTextBox ? 12 : 24}
    >
      <Box
        position="relative"
        px={[gutterWidth / 2, null, gutterWidth]}
        pr={showTextBox ? gutterWidth / 3 : [null, null, 0]}
        py={[
          imageTextBlock && !showBackgroundImage ? 0 : gutterWidth / 2,
          null,
          ['OVERLAPPING', 'FULL_WIDTH'].includes(appearance) ? gutterWidth : 0,
        ]}
        height="100%"
      >
        {label && (
          <HeadingSmall color={colors.lightContentGrey}>{label}</HeadingSmall>
        )}
        {headline && (
          <Box
            display="block"
            mb={24}
            color={colors.mediumContentGrey}
            textDecoration="none"
          >
            <RichTextHeadline>{headline}</RichTextHeadline>
          </Box>
        )}
        <RichText
          analyticsData={{
            eventName: 'CMS Block',
            eventProperties: {
              block_position: blockIndex,
              block_type: 'Split Content',
            },
          }}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
          styleAs="body"
          tag="div"
        />
        {button && !hideButton && (
          <Box mt={[24, null, 36]}>
            <CmsButtonLoadable
              buttonData={button}
              eventProperties={{
                block_position: blockIndex,
                block_type: 'Split Content',
                button_text: button.text,
                page_updated: pageUpdated,
              }}
              blockId={blockId}
              sectionIndex={index}
              render={({ data, children, title, 'data-qa-id': qaId }) => (
                <Button
                  data={data}
                  title={title}
                  variant="primary"
                  data-qa-id={qaId}
                >
                  {children}
                </Button>
              )}
            />
          </Box>
        )}
        {movementSignupButtonText && digits && (
          <>
            <Box display="flex" alignItems="center" mt="30px" flexWrap="wrap">
              <Box mr="16px">
                {digits.map((digit) => (
                  <CmsSplitContentBlockMovementCounterDigitContainer
                    data-qa-id="counter-digit"
                    data-qa-value={digit}
                  >
                    {digitsSlot(parseInt(digit, 10))}
                  </CmsSplitContentBlockMovementCounterDigitContainer>
                ))}
              </Box>
              <CmsSplitContentBlockMovementPeopleJoined>
                {getText('people have joined')}
              </CmsSplitContentBlockMovementPeopleJoined>
            </Box>
            {!movementUserSignedUp && (
              <Box mt="30px">
                <Button
                  type="button"
                  onClick={joinMovement}
                  data-qa-id="join-movement-button"
                >
                  {movementSignupButtonText}
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
