import { useMemo } from 'react';
import styled from 'styled-components';

import { colors, textStyles as styles, textStyles } from 'theme/theme';

import { RichText } from './RichText';

type Props = {
  children: string;
  className?: string;
  styleAs?: keyof typeof textStyles;
  tag?: string;
  textAlignCenter?: boolean;
  color?: string;
};

export const RichTextHeadline = styled(
  ({ children, className, tag, textAlignCenter, color, ...props }: Props) => {
    const html = useMemo(() => {
      if (children && children.indexOf('h2') > -1) return children;

      return `<h2>${
        children ? children.replace(/<(\/?)p>/g, '<$1span>') : ''
      }</h2>`;
    }, [children]);

    return (
      <RichText
        className={className}
        dangerouslySetInnerHTML={{
          __html: tag ? html.replace(/<(\/?)h2>/g, `<$1${tag}>`) : html,
        }}
        textAlignCenter={textAlignCenter}
        color={color}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  },
)<{
  $reverseUnderline?: boolean;
  $noMarginBottom?: boolean;
  $noMarginTop?: boolean;
}>`
  &&.idlst-rchtxt {
    ${(props) => props.tag || 'h2'} {
      /* block-no-empty */

      ${(props) => (props.$noMarginBottom ? '' : 'margin-bottom: 1em;')};
      ${(props) => props.$noMarginTop && 'margin-top: 0;'};

      ${(props) =>
        props.$reverseUnderline
          ? `
      strong,
      u,
      b,
      em {
        background-image: linear-gradient(
          to bottom,
          ${colors.brandBlue} 0%,
          ${colors.brandBlue} 100%
        );
      }`
          : ''}
    }

    ${(props) =>
      props.styleAs &&
      styles[props.styleAs] &&
      `
      ${props.tag || 'h2'} {
        ${styles[props.styleAs]}
      }
    `}
  }
`;
